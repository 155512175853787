import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

Builder.registerComponent(
    dynamic(() =>
        import('@/src/common/components/marketing/cards/StepCard').then(
            (res) => res as any
        )
    ),
    {
        name: 'Card with Step Details',
        inputs: [
            {
                name: 'image',
                type: 'file',
                allowedFileTypes: ['jpeg', 'png'],
                defaultValue:
                    'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2Ffc8ef20a3d2d49a8abb1452738f17541',
            },
            {
                name: 'imageHeight',
                type: 'string',
                enum: ['square', ''],
            },
            {
                name: 'altText',
                type: 'string',
                defaultValue: 'step information',
            },
            {
                name: 'blueText',
                type: 'string',
                defaultValue: 'step 1',
            },
            {
                name: 'headerText',
                type: 'string',
                defaultValue: 'Buyer purchases a PerfectGift+',
            },
            {
                name: 'descriptionText',
                type: 'string',
                defaultValue:
                    'Duis mollis, est non commodo luctus, nisi erat rottitor ligula.',
            },
            {
                name: 'popoutText',
                type: 'string',
                defaultValue: '1',
                friendlyName: 'Large gradient number',
            },
            {
                name: 'decoration',
                type: 'string',
                enum: ['squiggle-line', 'right-arrow', 'sparkle', 'yas'],
                friendlyName: 'Decorative icon',
            },
        ],
    }
)
