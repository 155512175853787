import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

export const PredesignPreviewBuilderInputs = [
    {
        name: 'header',
        type: 'string',
        required: true,
    },
    {
        name: 'visaOrMc',
        type: 'string',
        defaultValue: 'visa',
        enum: ['visa', 'mc'],
        required: true,
    },
]

Builder.registerComponent(
    dynamic(import('./PredesignPreview').then((res) => res as any)),
    {
        name: 'Predesign Preview',
        inputs: PredesignPreviewBuilderInputs,
    }
)
