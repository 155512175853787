import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

Builder.registerComponent(
    dynamic(() =>
        import('./ModalTriggerGreetingCards').then((res) => res as any)
    ),
    {
        name: 'Modal Trigger: Greeting Cards',
        // Adding defaults is important for easy usability
        defaultChildren: [
            {
                '@type': '@builder.io/sdk:Element',
                component: {
                    name: 'Text',
                    options: { text: 'I am child text block!' },
                },
            },
        ],
    }
)
