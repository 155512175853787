
import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

Builder.registerComponent(
    dynamic(() => import('./ContactUs').then((res) => res.ContactUs as any)),
    {
        name: 'Contact Us',
        // inputs: [
        //     {
        //         name: 'cardholderAction',
        //         type: 'string',
        //         defaultValue: ACTIVATE,
        //         options: [ACTIVATE, BALANCE],
        //         required: true,
        //     },
        // ],
    }
)
