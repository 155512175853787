import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'
import { PG_PLUS_BUILD_PAGE } from '@/src/modules/pg-plus/constants'

Builder.registerComponent(
    dynamic(() =>
        import('@/src/common/components/marketing/heros/PerfectGiftHero').then(
            (res) => res as any
        )
    ),
    {
        name: 'PerfectGift Hero',
        inputs: [
            {
                name: 'eyebrowText',
                type: 'string',
                defaultValue:
                    'Flexible choices, guaranteed smiles, delivered instantly.',
            },
            {
                name: 'bodyText',
                type: 'string',
                defaultValue:
                    "Gift with confidence. Explore endless options, customize for any occasion, and make someone smile. It's effortless and quick gifting, made perfect.",
            },
            {
                name: 'buttonLabel',
                type: 'string',
                defaultValue: 'buy a perfectgift+',
            },
            {
                name: 'buttonUrl',
                type: 'string',
                defaultValue: PG_PLUS_BUILD_PAGE,
            },
            {
                name: 'gradientText',
                type: 'string',
                defaultValue: 'PerfectGift+',
                friendlyName: 'Header Text (Gradient)',
            },
            {
                name: 'headerText',
                type: 'string',
                defaultValue: 'the Perfect Gift, Every Time',
                friendlyName: 'Header Text (Non-Gradient)',
            },
            {
                name: 'headerTextWidth',
                type: 'string',
                enum: ['', 'extra-small', 'small', 'small-medium'],
            },
            {
                name: 'imageUrl',
                type: 'file',
                allowedFileTypes: ['jpeg', 'png'],
                defaultValue:
                    'https://res.cloudinary.com/gift-card-granny/image/upload/v1715970763/PGSite/marketing_images/pgc-hero_ihyqfm.png',
            },
            {
                name: 'newLineHeader',
                type: 'boolean',
                defaultValue: 'true',
                friendlyName: 'Put non-gradient text on new line?',
            },
        ],
    }
)
