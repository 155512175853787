import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'
import { ACTIVATE, BALANCE } from './strings'

Builder.registerComponent(
    dynamic(() =>
        import('./CardActivate').then((res) => res.CardActivate as any),
    ),
    {
        name: 'Card Activate and Balance Check',
        inputs: [
            {
                name: 'cardholderAction',
                type: 'string',
                defaultValue: ACTIVATE,
                options: [ACTIVATE, BALANCE],
                required: true,
            },
            {
                name: 'showExampleCardImageWithoutNetwork',
                type: 'boolean',
                defaultValue: false,
            },
        ],
    },
)
