import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

Builder.registerComponent(
    dynamic(() =>
        import('./VirtualRedemption').then(
            (res) => res.VirtualRedemption as any
        )
    ),
    {
        name: 'Virtual Card Redemption',
        inputs: [
            {
                name: 'corporate',
                type: 'boolean',
                defaultValue: false,
            },
            // {
            //     name: 'cardholderAction',
            //     type: 'string',
            //     defaultValue: ACTIVATE,
            //     options: [ACTIVATE, BALANCE],
            //     required: true,
            // },
        ],
    }
)
