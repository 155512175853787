import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'
import { hubspotFormUuids } from '@/src/common/components/marketing/forms/hubspotConstants'

const formVariants = Object.keys(hubspotFormUuids)

Builder.registerComponent(
    dynamic(() => import('./LeadForm').then((res) => res as any)),
    {
        name: 'Lead Form',
        inputs: [
            {
                name: 'formVariant',
                type: 'string',
                enum: formVariants,
                defaultValue: formVariants[0],
            },
            {
                name: 'instructionTextNames',
                type: 'richText',
            },
            {
                name: 'instructionTextQuantityType',
                type: 'richText',
            },
            {
                name: 'instructionTextContact',
                type: 'richText',
            },
            {
                name: 'showPromoCodeInput',
                type: 'boolean',
            },
        ],
    }
)
