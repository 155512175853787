import { Builder, withChildren } from '@builder.io/react'
import dynamic from 'next/dynamic'

const defaultImage =
    'https://placehold.co/300x300/F4F4F4/white&bold&fontsize=16'
const defaultBio =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna alised do eiusmod temor incididunt ut labore et dolore magna.'

export const StaffBioInputs = [
    {
        name: 'flipContent',
        type: 'boolean',
        defaultValue: false,
    },
    {
        name: 'imageUrl',
        type: 'file',
        allowedFileTypes: ['jpeg', 'png'],
        defaultValue: defaultImage,
        friendlyName: 'Image',
    },
    {
        name: 'imagePosition',
        type: 'string',
        defaultValue: 'center',
        enum: ['bottom', 'top', 'center', 'left', 'right'],
    },
    {
        name: 'altText',
        type: 'string',
        friendlyName: 'Image alt text',
    },
    {
        name: 'fullName',
        type: 'text',
        defaultValue: 'Jason Wolfe',
    },
    {
        name: 'bioText',
        type: 'richText',
        defaultValue: defaultBio,
    },
    {
        name: 'phoneNumber',
        defaultValue: '800-543-7927',
        type: 'text',
    },
    {
        name: 'email',
        defaultValue: 'support@perfectgift.com',
        type: 'text',
    },
    {
        name: 'linkedIn',
        type: 'text',
        friendlyName: 'LinkedIn profile url',
    },
]

Builder.registerComponent(
    withChildren(dynamic(() => import('./StaffBio').then((res) => res as any))),
    {
        name: 'Staff Bio',
        inputs: StaffBioInputs,
        canHaveChildren: true,
    }
)
