import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

// @todo remove once all promo items are replaced in builder
const ButtonBuilderInputs = [
    {
        name: 'promoCode',
        type: 'string',
        defaultValue: 'ABC123',
    },
]

Builder.registerComponent(
    dynamic(import('./CopyPromoCodeButton').then((res) => res as any)),
    {
        name: 'Promo Code Button',
        inputs: ButtonBuilderInputs,
    }
)
