import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

const facebookUrl = 'https://www.facebook.com/PerfectGiftCom'
const twitterUrl = 'https://twitter.com/PerfectGiftcom'
const instagramUrl = 'https://www.instagram.com/perfectgiftcom/'
const youtubeUrl = 'https://www.youtube.com/channel/UCYAuggt2bUIdUokfLiv9piA'
const linkedinUrl = 'https://www.linkedin.com/company/perfectgiftcom/'
const iconColor = 'white'

export const SocialIconInputs = [
    {
        name: 'facebookUrl',
        type: 'string',
        defaultValue: facebookUrl,
    },
    {
        name: 'twitterUrl',
        type: 'string',
        defaultValue: twitterUrl,
    },
    {
        name: 'instagramUrl',
        type: 'string',
        defaultValue: instagramUrl,
    },
    {
        name: 'youtubeUrl',
        type: 'string',
        defaultValue: youtubeUrl,
    },
    {
        name: 'linkedinUrl',
        type: 'string',
        defaultValue: linkedinUrl,
    },
    {
        name: 'iconColor',
        type: 'string',
        defaultValue: iconColor,
    },
]

Builder.registerComponent(
    dynamic(import('./SocialShareIcons').then((res) => res as any)),
    {
        name: 'Social Media Icons',
        inputs: SocialIconInputs,
    }
)
