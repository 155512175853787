import { Builder } from '@builder.io/react'
import {
    pressCoverage2BuilderElement,
    pressCoverageBuilderElement,
} from '../press/PressCoverage.builder'
import dynamic from 'next/dynamic'

const ToggledColumnsBuilderInputs = [
    {
        name: 'toggleName1',
        type: 'string',
        defaultValue: 'News coverage',
    },
    {
        name: 'toggleName2',
        type: 'string',
        defaultValue: 'Press releases',
    },
    {
        name: 'column2HrefParameter',
        type: 'string',
        defaultValue: 'press-releases',
    },
    {
        name: 'showBuilderComponent1',
        type: 'boolean',
        defaultValue: true,
    },
    {
        name: 'showBuilderComponent2',
        type: 'boolean',
        defaultValue: true,
    },
    {
        name: 'builderComponent1',
        type: 'uiBlocks',
        defaultValue: [pressCoverageBuilderElement],
    },
    {
        name: 'builderComponent2',
        type: 'uiBlocks',
        defaultValue: [pressCoverage2BuilderElement],
    },
]

Builder.registerComponent(
    dynamic(() => import('./ToggledColumns').then((res) => res as any)),
    {
        name: 'Toggled Columns',
        canHaveChildren: true,
        inputs: ToggledColumnsBuilderInputs,
    }
)
