import { Builder } from '@builder.io/react'
import { AEThumbnail } from './AEThumbnail'

const componentName = 'AE-Thumbnail'

Builder.registerComponent(AEThumbnail, {
    name: componentName,
    inputs: [
        { name: 'name', friendlyName: 'Name', type: 'string' },
        { name: 'email', friendlyName: 'Email Address', type: 'email' },
        { name: 'linkedIn', friendlyName: 'LinkedIn URL', type: 'string' },
        { name: 'altText', friendlyName: 'Image Alt Text', type: 'string', defaultValue: '' },
        {
            name: 'imageUrl',
            friendlyName: 'Image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp'],
        },
        {
            name: 'imageSize',
            friendlyName: 'Image size (pixels)',
            type: 'number',
            defaultValue: 140,
        },
        {
            name: 'fontColor',
            friendlyName: 'Font Color',
            type: 'color',
            defaultValue: '#EDEEEF',
        },
        {
            name: 'fontSize',
            friendlyName: 'Font size (pixels)',
            type: 'number',
            defaultValue: 18,
        },
    ],
})
