import { theme } from '@/src/stitches.config'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const AEThumbnail = ({
    name,
    imageUrl,
    imageSize = 140,
    email,
    linkedIn,
    fontColor = `${theme.colors.light}`,
    fontSize = 18,
    altText,
}: {
    name: string
    imageUrl: string
    imageSize?: number
    email?: string
    linkedIn?: string
    fontColor?: string
    fontSize?: number
    altText?: string
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '12px',
                gap: '12px',
            }}
        >
            <img
                src={imageUrl}
                height={`${imageSize}px`}
                width={`${imageSize}px`}
                style={{ borderRadius: '50%', objectFit: 'cover' }}
                alt={altText}
            />
            <h4
                style={{
                    fontSize: `${fontSize}px`,
                    margin: '0',
                    color: fontColor,
                }}
            >
                {name}
            </h4>
            <div style={{ display: 'flex', gap: '12px' }}>
                {email && (
                    <a href={`mailto:${email}`}>
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            color={fontColor}
                            fontSize={`${fontSize}px`}
                        />
                    </a>
                )}
                {linkedIn && (
                    <a href={linkedIn}>
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            color={fontColor}
                            fontSize={`${fontSize}px`}
                        />
                    </a>
                )}
            </div>
        </div>
    )
}
