import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

const LeadFormInputs = [
    {
        name: 'builderVariant',
        type: 'reference',
        model: 'lead-form-content',
    },
    {
        name: 'leadFormHeaderType',
        type: 'string',
        defaultValue: 'h1',
        enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    },
    {
        name: 'quantitySelectRevealsContactFields',
        type: 'boolean',
        defaultValue: false,
    },
    {
        name: 'consumerRedirectOverride',
        type: 'string',
        defaultValue: '',
    },
]

Builder.registerComponent(
    dynamic(() => import('./LeadFormV2').then((res) => res as any)),
    {
        name: 'Lead Form V2',
        inputs: LeadFormInputs,
        canHaveChildren: false,
    }
)
