/* Field labels */
export const CARD_NUMBER_INPUT_LABEL = '16-digit card number'
export const CVV_INPUT_LABEL = '3-digit CVV code'
export const CODE_INPUT_LABEL = '4-digit activation code'
export const EMAIL_INPUT_LABEL = 'Email address'
export const PIN_NUMBER_INPUT_LABEL = '4-digit PIN'
export const CONFIRM_PIN_NUMBER_INPUT_LABEL = 'Confirm 4-digit PIN'
export const OPT_IN_INPUT_LABEL =
    'Sign up for email promotions, discounts & announcements'

/* Button text */
export const ACTIVATE_BUTTON_TEXT = 'Activate'
export const BALANCE_BUTTON_TEXT = 'Check Balance'

/* Field errors */
export const FIELD_ERROR_CREDIT_CARD_NUMBER_INVALID =
    'Credit card number is invalid'
export const FIELD_ERROR_CARD_NUMBER_INVALID = 'Card number is invalid'
export const FIELD_ERROR_CVV_NUMBER_INVALID = 'CVV number is invalid'
export const FIELD_ERROR_CVV_NUMBER_REQUIRED = 'CVV number is required'
export const FIELD_ERROR_EMAIL_INVALID = 'Enter a valid email'
export const FIELD_ERROR_EMAIL_REQUIRED = 'Email is required'
export const FIELD_ERROR_PIN_INVALID = 'PIN number is invalid'
export const FIELD_ERROR_CONFIRM_PIN_REQUIRED = 'Confirm PIN is required'
export const FIELD_ERROR_CONFIRM_PIN_INVALID = 'PIN numbers must match'
export const FIELD_ERROR_CODE_INVALID = 'Activation code is invalid'

/* Form errors */
export const ERROR_TEXT_RECAPTCHA_INVALID =
    'Unproccessable request, Invalid captcha code'

/* Action constants */
export const ACTIVATE = 'activate'
export const BALANCE = 'balance'

/* Activation "Type" constants */
export const RESTRICTED = 'restricted'
export const NORMAL = 'normal'
export const CODE = 'code'
