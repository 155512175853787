import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'
import { REFER_A_FRIEND_PAGE } from '@/src/modules/pg-plus/constants'

Builder.registerComponent(
    dynamic(() =>
        import('@/src/common/components/marketing/heros/ReferAFriendHero').then(
            (res) => res as any
        )
    ),
    {
        name: 'Refer A Friend Hero',
        inputs: [
            {
                name: 'bodyText',
                type: 'string',
                defaultValue:
                    'Refer a friend and earn discounts on your next purchase!',
            },
            {
                name: 'buttonLabel',
                type: 'string',
                defaultValue: 'Refer now',
            },
            {
                name: 'buttonUrl',
                type: 'string',
                defaultValue: REFER_A_FRIEND_PAGE,
            },
            {
                name: 'gradientText',
                type: 'string',
                defaultValue: 'You both get $5',
                friendlyName: 'Header Text (Gradient)',
            },
            {
                name: 'headerText',
                type: 'string',
                defaultValue: 'Refer a friend',
                friendlyName: 'Header Text (Non-Gradient)',
            },
            {
                name: 'imageUrl',
                type: 'file',
                allowedFileTypes: ['jpeg', 'png'],
                defaultValue:
                    'https://res.cloudinary.com/gift-card-granny/image/upload/v1717793539/PGSite/marketing_images/two-girls-with-dog_y07i7t.png',
            },
            {
                name: 'variant',
                type: 'string',
                enum: [
                    'standard',
                    'mobile-view-only',
                    'mobile-view-small-font',
                    'image-left',
                    'image-full-height',
                ],
            },
            {
                name: 'style',
                type: 'object',
                hideFromUI: true,
            },
            {
                name: 'showTermsAndConditions',
                type: 'boolean',
                defaultValue: true,
            },
        ],
    }
)
