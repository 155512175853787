import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

const BuilderInputs = [
    {
        name: 'promotionHeader',
        type: 'string',
        defaultValue: 'Free Shipping on USPS First Class!',
        friendlyName: 'Promo Header',
        required: true,
    },
    {
        name: 'promoCode',
        type: 'string',
        defaultValue: 'JANUARY24',
        friendlyName: 'Promo Code',
        required: true,
    },
    {
        name: 'linkUrl',
        type: 'string',
        defaultValue: '/predesigned-visa-gift-card',
        friendlyName: 'Button URL',
        required: true,
    },
    {
        name: 'image',
        type: 'file',
        defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2Fa39cf4a994e443c2b19fca046540be2a',
        allowedFileTypes: ['jpeg', 'png'],
        required: true,
    },
    {
        name: 'startDate',
        type: 'date',
        defaultValue: '11/11/11',
    },
    {
        name: 'endDate',
        type: 'date',
        defaultValue: '12/12/12',
    },
    {
        name: 'dateRange',
        type: 'string',
        defaultValue: '1/1/24 - 1/31/24',
        friendlyName: 'Date Range',
    },
    {
        name: 'promoTerms',
        type: 'richText',
        friendlyName: 'Promo Terms',
    },
]

Builder.registerComponent(
    dynamic(import('./PromoItem').then((res) => res as any)),
    {
        name: 'Promo Item',
        inputs: BuilderInputs,
    }
)
